import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { AboutBlurb } from "../pages/about"
import Social from "../components/social"

export default function Template({
  data,
}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="blog-template flex m-y-3">
        <article>
          <h1 className="m-b-2">{frontmatter.title}</h1>
          {frontmatter.thumbnail ? <img className="m-b-2" src={frontmatter.thumbnail}></img> : null}
          <div dangerouslySetInnerHTML={{ __html: html }}/>
        </article>
        <section className="sidebar">
          <h3 className="text-center">Join Me on Social Media</h3>
          <Social></Social>
          <AboutBlurb alt={true}></AboutBlurb>
        </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        thumbnail
      }
    }
  }
`