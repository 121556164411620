import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

export default function Qualifications() {
  return (
    <Layout>
      <QualificationsContent></QualificationsContent>
    </Layout>
  )
}

export function QualificationsContent() {
  return (
    <section className="flex justify-content-center-space-between halves m-y-2">

    <section className="bordered top left p-2">
      <h1 className="m-b-2">MY QUALIFICATIONS</h1>
      <h3 className="m-b-1">EDUCATION</h3>
      <ul>
        {[
          { year: 2011, text: 'Bachelor of Arts in Professional Writing, Metropolitan State University' },
          { year: 2011, text: 'Minor in Creative Writing, Metropolitan State University' },
          { year: 2009, text: 'Associate of Arts, Anoka-Ramsey Community College' }
        ].map(item => <li className="m-b-1"><strong>{item.year}:</strong> {item.text}</li>)}
      </ul>

      <h3 className="m-t-2 m-b-1">PROFESSIONAL DEVELOPMENT</h3>
      <ul>
        {[
          { year: 2020, text: 'The Science of Well-Being, Yale University' },
          { year: 2021, text: 'Google Analytics for Beginners, Google Analytics Academy' },
          { year: 2021, text: 'Introduction to Integrative Therapies and Healing Practices, Course one of five in the Integrative Health and Medicine Specialization, University of Minnesota' },
          { year: 2021, text: 'Guided Imagery, Course two in the Integrative Health and Medicine Specialization, University of Minnesota' },
          { year: 2021, text: 'Mindfulness in Integrative Healthcare, Course five in the Integrative Health and Medicine Specialization, University of Minnesota' },
          { year: 2022, text: 'Understanding Medical Research (Yale University via Coursera)' },
          { year: 2023, text: 'Introduction to Functional Medicine (IFM)' },
          { year: 2023, text: 'Micronutrient Testing and Thyroid Disease (IFM)' },
          { year: 2023, text: 'Micronutrient Deficiencies Associated With: Celiac disease, ketogenic diets, and PPI use (all through IFM)' },
        ].map(item => <li className="m-b-1"><strong>{item.year}:</strong> {item.text}</li>)}
      </ul>

    </section>

    <div>
      <img className="m-b-2" src="/assets/images/qualifications.1.jpg"></img>
      <section className="bordered bottom">
        <h1 className="m-b-2">PUBLISHED WORK</h1>
        <p>
          I’ve been ghostwriting for most of my professional writing career, but you can find some of my published work below.
        </p>

        <ul>
          {[
            { link: 'https://thriveglobal.com/stories/5-self-care-strategies-i-practice-daily-as-an-infj-and-hsp/', text: 'Thrive Global' },
            { text: 'Well From Within' },
            { link: 'https://themighty.com/2017/06/ovarian-colon-cancer-what-to-know-about-cancer/', text: 'The Mighty' },
            { link: 'http://www.globallookingglass.com/author/alexandrabeane/', text: 'Global Looking Glass' },
          ].map(item => item.link ? <li className="m-b-1"><a href={item.link} target="_blank">{item.text}</a></li> : <li className="m-b-1"><strong><Link to="/blog">{item.text}</Link></strong></li>)}
        </ul>
      </section>
    </div>

  </section>
  )
}
