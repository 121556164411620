import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { QualificationsContent } from "./qualifications"

export default function About() {
  return (
    <Layout>
      <React.Fragment>
        <AboutContent></AboutContent>
        <QualificationsContent></QualificationsContent>
      </React.Fragment>
    </Layout>
  )
}

export function AboutContent() {
  return (
    <section className="flex justify-content-center-space-between halves m-y-3">
      <AboutBlurb></AboutBlurb>

      <section className="bordered">
        <h1 className="m-b-2">WHY HEALTH & WELLNESS?</h1>
        <p>
          Though I’ve researched and written about many different topics over the years, wellness is my niche — especially food and nutrition, mind-body practices, and self-care. My passion for health and nutrition began several years ago, following a personal diagnosis with Lyme, ovarian cancer, Hashimoto’s disease, irritable bowel syndrome, and several other mystery ailments. 
        </p>
        <p>
          About a year before I was diagnosed with ovarian cancer, my mother was diagnosed with stage four colon cancer. Immediately following her diagnosis, she and I teamed up to learn as much as possible about health and nutrition. My mom continues to be my greatest inspiration, and I simply don’t know anyone who works so tirelessly for good health! 
        </p>
        <p>
          Learn more about my <Link to="/health-and-wellness">wellness journey</Link>. 
        </p>
      </section>
    </section>
  )
}

export function AboutBlurb({ alt = false }) {
  return (
    <div>
      <section className={`bordered m-b-2 ${alt ? 'less-padding' : null}`}>
        {alt ? null : <h1 className="m-b-2">ABOUT</h1>}
        <p>Hey, I'm Alex! As a freelance health writer, I thoroughly research and ghostwrite blog posts, newsletter articles, podcast scripts, and social media content for dietitians, nutritionists, functional medicine practitioners, physicians, health coaches, and other health + wellness professionals. If you're passionate about health and wellness, I'd love to work with you!</p>
      </section>
      {alt ? <img src="/assets/images/alex-n-babe.jpg"></img> : <img src="/assets/images/alex-about.jpg"></img>}
    </div>
  )
}
